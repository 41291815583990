import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useStaticQuery, graphql } from "gatsby";

const StyledCarousel = styled.section`
  .slick-slide {
    padding: 0 10px;
  }
  .slick-dots {
    position: relative;
    margin-top: 0;
  }
  .slick-slider {
    max-width: 90%;
    margin: 0 auto;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #286544;
    opacity: 1;
  }
`;

const Testimonials = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;

    script.setAttribute("data-use-service-core", true);

    // Set defer attribute
    script.defer = true;

    // Append script to the body
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const pageQuery = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          name: { eq: "testimonials" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              html
              frontmatter {
                messages {
                  author
                  message
                }
              }
            }
          }
        }
      }
    }
  `);

  const data =
    pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter.messages;

  const testimonialCards = data.map((card) => {
    return {
      text: card.message,
      author: card.author,
    };
  });

  //console.log(testimonialCards)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cards = [
    {
      text: "Tareq and his team are absolutely incredible! We are so so happy with the Garden Room and it is everything we wanted and more and it has made such a difference to the way we work and enjoy our garden. I have never experienced such a professional, talented and kind team before! The quality of the Garden Room and the standard of the workmanship is incredible! We have had so many compliments already! Tareq and his team were always on time, extremely helpful, reliable, friendly and always kept us updated on each step of the build and with progress and overall have done an outstanding job in terms of the product provided. Tareq and the team work really hard, are really polite and considerate, do an amazing job and have really gone above and beyond to ensure that we love the Garden Room and we could not be happier with the final result and the whole process - thank you so much Tareq, Jeremy, Mush & Rhys, we are so grateful for everything you've done and would recommend you to anyone looking to get a Garden Room. Alice & Christian",
      author: "Alice",
      location: "Cardiff",
    },
    {
      text: "Cardiff Garden Rooms were professional from start to finish. Always on time, always keeping us in the loop if there were minor delays and the team were a joy to have around.  So polite and caring.  The Garden Room itself is amazing.  Just what we wanted and looks stunning.  Thank you Tariq and the team.",
      author: "Helen",
      location: "Cardiff",
    },
    {
      text: "Cardiff Garden rooms were incredible right from the first day we got in contact until the last few details were finished. The garden room not only looks beautiful but the quality is next level. The price was very competitive and every single person that we met who worked within the company was so friendly and professional!",
      author: "James and Carys",
      location: "Cardiff",
    },
    {
      text: "First came across Cardiff Garden Rooms on Instagram..... Didnt think id find a company like them on there.. But there they were. From first contact its been a real pleasure working with Tareq and the team and i literally could not recommend  them highly enough. Workmanship, Reliability, Space planning, Materials used, the People, all of the highest quality and professionalism and also not forgetting delivering a really good competentivie price.... If you want a garden room... you would be silly not to approach Cardiff Garden Rooms. ",
      author: "James",
      location: "Cardiff",
    },
    {
      text: "Top level of service from Tariq and his team. The whole team were very professional, always kept us up to date and went over and above in ensuring that everything was exactly as we wanted with the room. The quality and finish and spec are all excellent.",
      author: "l Takeuchi",
      location: "Cardiff",
    },
  ];

  return (
    <div className="bg-green mt-med">
      <div className="container">
        <h2 className="text-center" style={{ marginBottom: "40px" }}>
          See what our customers think...
        </h2>
        <div
          className="elfsight-app-b5e24105-16b9-4cf4-b453-14a68638a420"
          data-elfsight-app-lazy
        ></div>
      </div>
    </div>

    // <StyledCarousel className="bg-green mt-med">
    //   <div className="container">
    //     <h2 className="text-center">See what our customers think...</h2>
    //     <Slider {...settings}>
    //       {testimonialCards.map((card, i) => (
    //         <div className="box" key={i}>
    //           <p>“{card.text}”</p>
    //           <p className="sub mb-none">{card.author}</p>
    //           {/* <p className="mt-none">Roath, Cardiff</p> */}
    //         </div>
    //       ))}
    //     </Slider>
    //   </div>
    // </StyledCarousel>
  );
};

export default Testimonials;
