import React, { useState } from "react";
import styled from "styled-components";
import FsLightbox from "fslightbox-react";
import { useStaticQuery, graphql } from "gatsby";

const options = {
  settings: {
    overlayColor: "rgb(0, 0, 0, 0.9)",
  },
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
  },
};

const GalleryStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50vw 33vw;
  grid-gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 50% 50%;
  }

  .gallery__item {
    @media (min-width: 768px) {
      position: relative;
    }
  }

  .gallery__item:nth-of-type(1) {
    grid-column: 1/3;
    grid-row: 1;

    @media (min-width: 768px) {
      grid-column: 1/2;
      grid-row: 1/3;

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: 100%;
      }
    }
  }

  .gallery__item:nth-of-type(2) {
    @media (min-width: 768px) {
      grid-column: 2/4;
      grid-row: 1/2;
    }
  }

  .gallery__item:nth-of-type(4) {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .gallery__item img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
    }
  }
`;

const Gallery = () => {
  const [toggler, setToggler] = useState(false);

  const pageQuery = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          name: { eq: "gallery" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                gallery
              }
            }
          }
        }
      }
    }
  `);

  const data = pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter;
  const gallery = [];
  data.gallery.forEach((img) => {
    gallery.push(img);
  });

  return (
    <>
      <section id="gallery">
        <div className="container container--wide text-center">
          <GalleryStyles className="gallery">
            <div
              href="cardiffgardenrooms-gallery1.jpg"
              className="gallery__item"
            >
              <img
                src="/cardiffgardenrooms-gallery1.jpg"
                alt="A garden room by Cardiff Garden Rooms"
              />
            </div>
            <div
              href="cardiffgardenrooms-gallery2.jpg"
              className="gallery__item"
            >
              <img
                src="/cardiffgardenrooms-gallery2.jpg"
                alt="Cardiff Garden Room lighting"
              />
            </div>
            <div
              href="cardiffgardenrooms-gallery4.jpg"
              className="gallery__item"
            >
              <img
                src="/cardiffgardenrooms-gallery4.jpg"
                alt="Exterior of a Cardiff Garden Room"
              />
            </div>
            <div className="gallery__item">
              <img
                src="/cardiffgardenrooms-gallery3.jpg"
                alt="Interior of a Cardiff Garden Room"
              />
            </div>
          </GalleryStyles>
          <br></br>
          <a
            href="#"
            className="btn"
            onClick={() => setToggler(!toggler)}
            style={{ display: "inline-block", margin: "20px auto 0" }}
          >
            View more photos
          </a>
          <FsLightbox toggler={toggler} sources={gallery} />
        </div>
      </section>
    </>
  );
};

export default Gallery;
