import React from "react";
import { Helmet } from "react-helmet";

const HeadStuff = ({ title, description }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description}></meta>

      <meta property="og:url" content="https://cardiffgardenrooms.co.uk"></meta>
      <meta property="og:type" content="website"></meta>
      <meta
        property="og:title"
        content="Cardiff Garden Rooms: Bespoke and insulated ideal for home office, gym and more"
      ></meta>
      <meta
        property="og:description"
        content="Cardiff Garden Rooms build bespoke, insulated garden rooms to suit your requirements. If you are looking for a home office, gym or living space, we can help."
      ></meta>
      <meta
        property="og:image"
        content="https://cardiffgardenrooms.co.uk/static/CardiffGardenRooms-736cb97c2c68228251163038b2daceed.jpg"
      ></meta>

      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta
        name="twitter:title"
        content="Cardiff Garden Rooms: Bespoke and insulated ideal for home office, gym and more"
      ></meta>
      <meta
        name="twitter:description"
        content="Cardiff Garden Rooms build bespoke, insulated garden rooms to suit your requirements. If you are looking for a home office, gym or living space, we can help."
      ></meta>
      <meta
        name="twitter:image"
        content="https://cardiffgardenrooms.co.uk/static/CardiffGardenRooms-736cb97c2c68228251163038b2daceed.jpg"
      ></meta>
    </Helmet>
  );
};

export default HeadStuff;
